import styled from "styled-components";
import imgMiniaturas from "../images/background-miniaturas-landing-page-edit.png";

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin: auto;
`;

export const Center = styled.div`
  width: 100%;
  max-width: 1440px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  p {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
  }

  .landing__intro {
    width: 100%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
  
    h1 {
      align-self: flex-end;
      color: #000000;
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 1.5em;
    }
  }

  .landing__text {
    width: 100%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;

    h2 {
      width: 100%;
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 55px;
      text-align: justify;
      padding: 0 90px;
    }
  }

  .background-miniaturas {
    width: 97%;
    /* width: 100%; */
    height: 174px;

    background-image: url(${imgMiniaturas});
    background-size: 1000px;
    background-repeat: repeat-x;
    margin: 9em 0 4em;
  }

  .whiteList {
    width: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    margin: 2em auto;

    p {
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 24px;
      margin: 1em;
    }

    .input {
      width: 400px;
      height: 46px;

      border-radius: 3px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      background-color: #b9bebe;

      input {
        width: 90%;
        height: 100%;
        border: none;
        outline: none;
        padding: 10px;
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.5);
        background-color: transparent;
      }

      input::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 16px;
      }

      @media screen and (max-width: 500px) {
        width: 90vw;
      }
    }

    @media screen and (max-width: 500px) {
      width: 80%;
      height: 120%;
    }
  }
  .text-02 {
    margin: 40px 0 -80px 0;
  }

  .text-03{
    line-height: 54px;
  }

  //// Responsividade ////
  @media all and (max-width: 768px) {
    & .landing__intro,
    .landing__text{
      padding: 0 30px;
    }

    // Título
    & .landing__intro_title {
      font-size: 24px!important;
      text-align: center;
      line-height: 1.5!important;
      align-self: center!important;
    }

    // Espaçamento de linha dos parágrafos
    & .text-01, .text-02 {
      line-height: 35px;
    }

    & .text-03 {
      line-height: 40px;
    }

    // Fonte dos parágrafos
    p {
      font-size: 18px!important;
    }

    & .background-miniaturas {
      margin: 8em 0 3em;
    }
  }
`;

export const Button = styled.a`
  width: 161px;
  height: 61px;
  padding: 8px 30px;  
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #336699;
  font-family: "Gloria Hallelujah";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin: 3em 0 0;
  text-align: center;
  text-decoration: none;
  line-height: 48px;
  color: #fff;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  @media screen and (max-width: 500px){
    margin: 2em;
  }
`;
