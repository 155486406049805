import styled from "styled-components";
import { Link } from "gatsby";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 120px;

  margin: auto;

  display: flex;
  flex-direction: column;

  margin-bottom: 50px;

  .header-1 {
    background-color: #336699;
    color: #336699;
  }

  .header-2 {
    width: 100%;
    display: flex;
    align-items: flex-start;

    padding: 25px 0;
  }

  .div-logo {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 500px){
      width: 100%;
      justify-content: flex-start;
    }
  }

  .logo {
    width: 250px;

    @media screen and (max-width: 500px){
      width: 170px;
      margin-left: 1em;
    }
  }

  .header-menu {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    margin: 20px 40px;
  }

  @media screen and (max-width: 1024px) {
    /* display: none; */
  }
`;

export const Li = styled(Link)`
  text-decoration: none;
  color: #000000;
  font-family: "Gloria Hallelujah", cursive;
  font-weight: 400;
  font-size: 20px;
  line-height: 48px;
`;
