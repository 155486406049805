import React, { useState } from "react";
import { Center, Container, Button } from "../../styles/LandingPage";
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header/MenuHorizontalLanding";
import Footer from "../../components/Footer";
import { saveContact } from "../../data/saveEmail";

function LandingPage() {
  const [email, setEmail] = useState('');

  async function sendEmail(sheet) {
    const whiteList = {
      email: email,
    };
    const result = await saveContact(whiteList, sheet)
    if (result) {
      setEmail('')
    }
  }

  return (
    <Container>
      <Header />
      <Center>
        <div className="landing__intro">
          <h1 className="landing__intro_title">Welcome to BrighterHeads!</h1>
          <p className="text-01">
          This unique project aims to inspire younger generations and 
          preserve the stories and teachings of brilliant minds, 
          encouraging leaders and creators through fun and contemporary means, 
          such as exclusive art, T-shirts, NFTs, and more.
          </p>
          <p className="text-02">
          Starting with an exclusive T-shirt collection and a 1500-unique NFT series on the Polygon Blockchain, 
          with 500 NFTs available for free.
          </p>
        </div>
        <div className="background-miniaturas"></div>
        <div className="landing__text">
          <p className="text-03">
          Throughout history, inspiring personalities have left a lasting impact by pushing the boundaries 
          of what was considered possible. They broke new ground with ideas that went beyond the status quo, 
          creating a legacy that continues to inspire generations to come.
          </p>
          {/* <Button href="/Home">Enter</Button> */}
        </div>
        <div className="whiteList">
          <p>Get on the white list</p>
          <div className="input">
            <input
              type="email"
              placeholder="e-mail address"
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <BsArrowRight
              style={{ marginRight: 10, cursor: "pointer" }}
              size={25}
              color="#000"
              onClick={() => sendEmail('whitelist')}
            />
          </div>
        </div>
      </Center>
      <Footer />
    </Container>
  );
}

export default LandingPage;
