import styled from "styled-components";
import { Link } from "gatsby";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  align-content: center;
  margin: 0 auto;

  background-color: #336699;

  z-index: 9 !important;

  * {
  box-sizing: content-box;
}

  /* @media screen and (max-width: 1440px) {
    width: 1335px;
    margin: auto;
  } */

  /* @media screen and (max-width: 1280px) {
    width: 1250px;
  } */

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    align-items: center;
    padding: 1em;
    margin: 0;
  }

  .copy,
  .logo-footer {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;

    .sociais {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      margin-left: 25px;
      padding-top: 10px;

      img {
        width: 37px;
      }

      svg, img {
        box-sizing: content-box;
      }
    }
  }

  .copy {
    @media screen and (max-width: 500px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .sociais {
    @media screen and (max-width: 500px) {
      margin-left: 0!important;
      padding-top: 1.5rem!important;
    }
  }

  .logo-footer {
    @media screen and (max-width: 1024px) {
      padding-right: 0!important;
      gap: 0.5!important;
    }
  }

  .private {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 400;
    /* margin-left: 20px; */

    p {
      color: rgba(230, 230, 230, 0.5);
      margin-bottom: -10px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      margin-left: 0;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      p {
        margin: 1em;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 15px;

      > a {
        cursor: pointer;
        text-decoration: underline;
        color: rgba(230, 230, 230, 0.5);

        &:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
  }

  .logo-footer {
    justify-content: space-evenly;
    padding-right: 1rem;

    @media screen and (max-width: 1024px) {
      padding-top: 20px;
      gap: 10px;
      flex-direction: column-reverse;
      img {
        width: 200px !important;
      }
    }

    > p {
      font-family: "Raleway", sans-serif;
      font-size: 24px;
      font-weight: 700;
      align-self: flex-end;
      min-width: 200px;
      margin: 24px 0%;

      color: #b3b3b3;

      @media screen and (max-width: 1024px) {
        font-size: 15px;
        align-self: center;
        margin: 0.5em 0 1em 0;
      }
    }
  }
`;

export const Li = styled(Link)`
  > img {
    padding: 8px;

    @media screen and (max-width: 1024px) {
      width: 35px;
    }
  }
`;
