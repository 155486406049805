import * as React from "react";
import { Container, Li } from "./style";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";
import logo from "../../images/logo_footer.png";
import openSea from "../../images/openSea.png";

const FooterComponent = () => {
  return (
    <Container>
      <div className="copy">
        <div className="sociais">
          <Li to="#">
            <FaXTwitter
              style={{ padding: "0 4px" }}
              color="#fcddec"
              size={37}
            />
          </Li>
          <Li to="#">
            <FaInstagram
              style={{ padding: "0 4px" }}
              color="#fcddec"
              size={37}
            />
          </Li>
          <Li to="#">
            <img src={openSea} alt="" />
          </Li>
        </div>
        <div className="private">
          <p>&copy; 2022 birghterheads</p>
          <div>
            <a href="/PrivacyPolicy">Privacy Policy</a>
            <a href="/TermsOfUse">Terms of Use</a>
          </div>
        </div>
      </div>
      <div className="logo-footer">
        <p>Be closer to brighter values.</p>
        <img style={{ width: 300 }} src={logo} alt="" />
      </div>
    </Container>
  );
};

export default FooterComponent;
