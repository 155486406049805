import * as React from "react";

import logo from "../../../images/logo.png";

import { Container, Li } from "./style";

const MenuHorizontal = () => {
  return (
    <Container>
      <div className="header-1">...</div>
      <div className="header-2">
        <div className="div-logo">
          <img className="logo" src={logo} />
        </div>
        {/* <div className="header-menu">
          <Li to="/">Beyond the Collectibles...</Li>
          <Li to="/gallery">Gallery</Li>
          <Li href="/#roadmap" to="">
            Mind Summary
          </Li>
          <Li href="/#theart" to="">
            The art
          </Li>
          <Li href="/#team" to="">
            Team
          </Li>
          <Li to="">Sign up</Li>
          <Li to="">ign in</Li>
        </div> */}
      </div>
    </Container>
  );
};

export default MenuHorizontal;
